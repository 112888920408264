<template>
  <div>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="상품관리" title="상품등록" />
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
<!--            <div class="card-header">-->
<!--              <h5>Add Product</h5>-->
<!--            </div>-->
            <div class="card-body">
              <div class="form-row">
                <div class="col-6 ">
                  <div class="form-group">
                    <label class="form-label d-inline" for="goodsName">상품명</label>
                    <input type="text" class="form-control" id="goodsName" name="goods_name" v-model="item.goods_name" aria-describedby="adNameHelp" placeholder="상품명" required>
                  </div>
                  <div class="form-group d-none">
                    <label class="form-label">상품구분</label>
                    <label class="custom-control custom-radio custom-control-inline ml-2">
                      <input type="radio" class="custom-control-input" name="goods_type" value="GOODS_TYPE01" checked v-model="item.goods_type">
                      <span class="custom-control-label">희망거래 판매용</span>
                    </label>
                    <label class="custom-control custom-radio custom-control-inline">
                      <input type="radio" class="custom-control-input" name="goods_type" value="GOODS_TYPE02" v-model="item.goods_type">
                      <span class="custom-control-label">조각모음 당첨전용</span>
                    </label>
                    <div class="form-inline d-inline-block">
                      <small class="form-text text-muted d-inline">조각모음 당첨전용 : 이벤트 조각 수집 완료 시 보상으로 제공될 수 있는 상품</small>
                    </div>
                  </div>
                  <div class="form-group" style="display: none;">
                    <label class="form-label">옵션여부</label>
                    <label class="custom-control custom-radio custom-control-inline ml-2">
                      <input type="radio" class="custom-control-input" name="option_yn" value="N" checked>
                      <span class="custom-control-label">미사용</span>
                    </label>
                    <label class="custom-control custom-radio custom-control-inline ml-2">
                      <input type="radio" class="custom-control-input" name="option_yn" value="Y">
                      <span class="custom-control-label">사용</span>
                    </label>
                  </div>
                  <div class="form-group" id="option-container" style="display: none;">
                    <label class="form-label d-inline" for="optionPlus">옵션추가</label><small class="form-text text-muted d-inline ml-2"></small>
                    <div id="optionPlus" class="fa fa-plus-circle fa-lg text-primary"></div>
                    <div id="optionStep" class="row">
                      <div class="col-12 input-group mb-1">
                                                        <span class="input-group-prepend">
                                                            <span class="input-group-text">1</span>
                                                        </span>
                        <input type="text" class="form-control form-inline col input-option" name="option_names[]" placeholder="옵션명">
                        <input type="text" class="form-control form-inline col-3 input-option" name="option_prices[]" placeholder="옵션추가금액">
                        <span class="input-group-append">
                                                            <span class="input-group-text"><i class="fa fa-check-circle fa-lg" aria-hidden="true"></i></span>
                                                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="form-label">전체판매 수량</label>
                    <div class="form-inline d-inline-flex ml-2">
                      <input type="number" class="form-control col-4" name="goods_qty" value="" required v-model="item.goods_qty">
                      <!-- <span class="ml-1">등록 후 수정 불가</span> -->
                      <small class="form-text text-muted d-inline"> 등록 후 수정 불가</small>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="form-label">최대구매 수량</label>
                    <div class="form-inline d-inline-flex ml-2">
                      <input type="number" class="form-control col-4" name="max_qty" value="" required v-model="item.max_qty">
                      <!-- <span class="ml-1">고객이 한번에 최대 구매 가능한 수량</span> -->
                      <small class="form-text text-muted d-inline"> 고객이 한번에 최대 구매 가능한 수량</small>
                    </div>
                  </div>
                  <div class="form-group d-none">
                    <label class="form-label d-inline">기간설정여부 </label>
                    <label class="switch switch-icon switch-outline-alt-primary form-control-label ml-2 mb-0 align-middle">
                      <input type="checkbox" class="switch-input form-check-input form-control-sm" name="use_date" value="" id="isDate" checked>
                      <span class="switch-label"></span>
                      <span class="switch-handle"></span>
                    </label>
                  </div>
                  <div class="form-group">
                    <label class="form-label d-inline" for="ct_date">판매기간</label>
                    <div class="input-daterange form-inline" id="dateTerm">
                      <date-picker v-model="item.start_date" format="YYYY.MM.DD" type="date" valueType="format" />
<!--                      <input type="text" class="form-control d-inline" name="start_date" id="startDate" readonly required />-->
                      <span class="mx-2 bg-white">~</span>
                      <date-picker v-model="item.end_date" format="YYYY.MM.DD" type="date" valueType="format" />
<!--                      <input type="text" class="form-control d-inline" name="end_date" id="endDate" readonly required />-->
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="form-label">판매금액</label>
                    <div class="form-inline d-inline-flex ml-2">
                      <input type="number" class="form-control" id="goodsPrice" name="goods_price" aria-describedby="adNameHelp" placeholder="판매금액" required v-model="item.goods_price" @input="goodsPriceFn($event)">
                    </div>

                  </div>
                  <div id="use-discount" class="d-none">
                    <div class="form-group">
                      <label class="form-label">할인 유무</label>
                      <label class="switch switch-icon switch-outline-alt-primary form-control-label ml-2" style="margin-top: -4px;">
                        <input type="checkbox" id="useDiscount" name="use_discount" class="switch-input form-check-input form-control-sm" value="">
                        <span class="switch-label"></span>
                        <span class="switch-handle"></span>
                      </label>
                    </div>
                  </div>
                  <div id="discount-wrap">
                    <div class="input-group">
                      <label class="form-label">할인구분</label>
                      <label class="custom-control custom-radio custom-control-inline ml-2" @click="discountTypeFn(1)">
                        <input type="radio" class="custom-control-input" name="discount_type" value="DISCOUNT_TYPE01" checked v-model="item.discount_type">
                        <span class="custom-control-label">비율</span>
                      </label>
                      <label class="custom-control custom-radio custom-control-inline" @click="discountTypeFn(2)">
                        <input type="radio" class="custom-control-input" name="discount_type" value="DISCOUNT_TYPE02" v-model="item.discount_type">
                        <span class="custom-control-label">금액</span>
                      </label>
                      <div class="form-inline d-inline-block">
                        <small id="EventBlockHelp" class="form-text text-muted d-inline">비율 : 일정비율 범위내 딜 제공,</small>
                        <small id="EventBlockHelp" class="form-text text-muted d-inline ml-1">금액 : 지정금액 내 딜 제공</small>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="form-label d-inline" for="discountStep">할인범위</label><small class="form-text text-muted d-inline ml-2">할인범위 금액 또는 비율 입력하세요.</small>
                      <span @click="discountPlus"><i  class="fa fa-plus-circle fa-lg text-primary"></i></span>
                      
                      <div id="discountStep" class="row">
                        <div v-for="(item, index) in discountRange" :key="index">

                          <div class="col-12 input-group mb-1" >
                            <span class="input-group-prepend"  style="width: 40px;">
                                <span class="input-group-text"  style="width: 40px;">{{ index+1 }}</span>
                            </span>
                            <input type="number" class="form-control form-inline col-2 discount-starts" name="discount_starts[]" placeholder="시작범위 금액" required v-model="item.discount_starts" :disabled="discount_type1" @input="discountRangeFn($event, index, 'discount_starts')">
                            <span class="input-group-prepend" style="width: 40px;">
                                <span class="input-group-text"  style="width: 40px;">원</span>
                            </span>
                            <span class="input-group-prepend" style="width: 40px;">
                                <span class="input-group-text"  style="width: 40px;">~</span>
                            </span>

                            <input type="number" class="form-control form-inline col-2 discount-ends active" name="discount_ends[]" placeholder="종료범위 금액" required v-model="item.discount_ends" :disabled="discount_type1"  @input="discountRangeFn($event, index, 'discount_ends')">
                            <span class="input-group-prepend" style="width: 40px;">
                                <span class="input-group-text"  style="width: 40px;">원</span>
                            </span>
                            <input type="number" class="form-control form-inline col-3 discount-qtys" name="discount_qtys[]" placeholder="할인 수량" required v-model="item.discount_qtys">
                            <span class="input-group-append" style="width: 40px;">
                                <span class="input-group-text" @click="discountMinus(item)"><i class="fa  fa-lg" :class="index > 0 ? 'fa-minus-circle text-danger' : 'fa-check-circle' " aria-hidden="true"  style="width: 40px;"></i></span>
                            </span>
                          </div>
                          <div class="col-12 input-group mb-1" >
                            <span class="input-group-prepend" style="width: 40px;">
                                <span class="input-group-text"  style="width: 40px;"></span>
                            </span>
                            <input type="number" class="form-control form-inline col-2 discount-starts" name="start_ratio[]" placeholder="시작 비율" required v-model="item.start_ratio" :disabled="discount_type2" @input="discountRangeFn($event, index, 'start_ratio')">
                            <span class="input-group-prepend" style="width: 40px;">
                                <span class="input-group-text"  style="width: 40px;">%</span>
                            </span>
                            <span class="input-group-prepend" style="width: 40px;">
                                <span class="input-group-text"  style="width: 40px;">~</span>
                            </span>

                            <input type="number" class="form-control form-inline col-2 discount-ends" name="end_ratio[]" placeholder="종료 비율" required v-model="item.end_ratio" :disabled="discount_type2" @input="discountRangeFn($event, index, 'end_ratio')">
                            <span class="input-group-prepend" style="width: 40px;">
                                <span class="input-group-text"  style="width: 40px;">%</span>
                            </span>
                            <span class="input-group-append col-3" style="padding-left: 0px; padding-right: 0px;">
                                <span class="input-group-text col-12"></span>
                            </span>
                            <span class="input-group-append" style="width: 66px;">
                                <span class="input-group-text"  style="width: 66px;"></span>
                            </span>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div id="discount-wrap">
                    <div class="form-group">
                      <small class="form-text text-muted">상품 수령 방법</small>
                      <small class="form-text text-muted">직접 : 사용자와 판매자가 직접 만나서 지갑간거래</small>
                      <small class="form-text text-muted">쿠폰 : 디지털 쿠폰으로 발행 - 사용자가 요청시 바로 교환</small>
                      <small class="form-text text-muted">배송 : 사용자가 요청시 상품을 배송받는경우 - 사용자가 배송지 넣고 요청 후 판매자가 배송</small>
                    </div>
                    <div class="form-group">
                      <select class="form-control" name="delivery_type" required v-model="item.delivery_type">
                          <option value="">상품 수령 방법</option>
                          <option value="DELIVERY_TYPE01">직접</option>
                          <option value="DELIVERY_TYPE02">쿠폰</option>
                          <option value="DELIVERY_TYPE03">배송</option>
                      </select>
                    </div>
                  </div>

                </div>
                <div class="col-6">
                  <div class="row">
                    <div class="col-8">
                      <div class="form-group">
                        <label class="form-label d-inline" for="goodsImg">메인화면 상품 이미지(가로형:pc버전)</label>
                        <small class="text-muted ml-2">최적화 사이즈 : 2740 * 744, 권장 포맷 : png, jpg</small>

                        <div class="custom-file">
                        <input type="file" class="custom-file-input" name="goods_thumbnail" id="goodsImg" accept="image/*" required @change="changeImg" >
                          <label class="custom-file-label">{{goods_img_name}}</label>
                        </div>
                      </div>
                    </div>
                    <div class="col show-img" id="showThumbnail"><img :src="showImg" class="box-input-file" /></div>
                  </div>
                  <div class="row">
                    <div class="col-8">
                      <div class="form-group">
                        <label class="form-label d-inline" for="goodsImg2">메인화면 상품 이미지(세로형:모바일)</label>
                        <small class="text-muted ml-2">최적화 사이즈 : 672 * 1000, 권장 포맷 : png, jpg</small>

                        <div class="custom-file">
                        <input type="file" class="custom-file-input" name="goods_thumbnail" id="goodsImg2" accept="image/*" required @change="changeImg2" >
                          <label class="custom-file-label">{{goods_img2_name}}</label>
                        </div>
                      </div>
                    </div>
                    <div class="col show-img" id="showThumbnail"><img :src="showImg2" class="box-input-file" /></div>
                  </div>
                  <div class="row">
                    <div class="col-8">
                      <div class="form-group">
                        <label class="form-label d-inline" for="goodsThumbnail">상품메인 이미지(NFT에 사용)</label>
                        <small class="text-muted ml-2">최적화 비율 : 13:9</small>

                        <div class="custom-file">
                        <input type="file" class="custom-file-input" name="goods_thumbnail" id="goodsThumbnail" accept="image/*" required @change="changeMain" >
                          <label class="custom-file-label">{{mainFile}}</label>
                        </div>
                      </div>
                    </div>
                    <div class="col show-img" id="showThumbnail"><img :src="showMain" class="box-input-file" /></div>
                  </div>
                  <div class="form-group">
                    <label class="form-label d-inline" for="goodsDetail">상품상세 이미지</label>
                    <small id="adImageHelp" class="text-muted ml-2">다중 선택 가능</small>
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" name="goods_detail[]" id="goodsDetail" multiple accept="image/*" required @change="changeDetail">
                      <label class="custom-file-label">{{detailFile}}</label>
                    </div>
                  </div>
                  <section class="character-twin text-center">
                    <div id="sliceWrap" class="slice-wrap">

                    </div>
                    <div id="showImg" class="text-center">
                      <div class="d-inline-block align-top px-1" style="width: 33%" v-for="(i, index) in detailImages" :key="index">
                        <img :src="i" class="box-input-file w-100" />
                      </div>
                    </div>
                  </section>

                </div>
              </div>
              <div class="text-center">
                <button type="button" class="btn btn-primary mr-1" id="btn-save" @click="save">등록완료</button>
                <router-link to="/goods/goodsList"><a type="button" class="btn btn-danger ml-1">돌아가기</a></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ko';
import nodeApi from "../../api/nodeApi";
import Vue from "vue";
import router from "../../router";

export default {
  components: {DatePicker},
  data() {
    return {
      counter: 0,
      content: "<h1>Some initial content</h1>",
      image: [],
      imageUrl: "../../assets/images/pro3/33.jpg",
      getImgUrl:"@assets/images/",
      discountRange: [{discount_starts: '', discount_ends: '', discount_qtys: '', start_ratio: 0, end_ratio:0}],
      item: {
        detail_images: [],
        goods_type: 'GOODS_TYPE01',
        discount_type: 'DISCOUNT_TYPE02',
        goods_price:0,
        goods_name:'',
        goods_qty:0,
        start_date:'',
        end_date:'',
        max_qty:0,
      },
      showMain: null,
      mainFile: '상품메인 이미지 파일 선택',
      detailImages: [],
      detailFile: '상품상세 이미지 파일 선택',

      goods_img_name:'메인화면 pc버전 이미지 파일 선택',
      goods_img2_name:'메인화면 모바일버전 이미지 파일 선택',
      showImg: null,
      showImg2: null,
      discount_type1: false,
      discount_type2: true,
      delivery_type: ''
    };
  },
  created() {
    this.$log.debug(['created', this.item]);
  },
  methods: {
    save() {
      this.$log.debug(['save', this.item, this.discountRange]);
      this.$log.debug(['this.item.goods_name', this.item.goods_name]);
      if(this.item.goods_name == ''){
        alert("상품명을 입력해주세요.");
        return;
      }
      if(this.item.goods_qty == ''){
        alert("전체판매 수량을 입력해주세요.");
        return;
      }
      if(this.item.max_qty == ''){
        alert("최대구매 수량을 입력해주세요.");
        return;
      }
      if(this.item.start_date == '' || this.item.end_date == ''){
        alert("판매기간을 입력해주세요.");
        return;
      }
      if(this.item.goods_price == 0){
        alert("판매금액을 입력해주세요.");
        return;
      }
      if(this.item.delivery_type == ''){
        alert("상품 수령 방법을 입력해주세요.");
        return;
      }
      for(let i = 0; i < this.discountRange.length; i++){
        console.log(['i', i]);
        if(typeof(this.discountRange[i].discount_starts) == "string"){
          this.discountRange[i].discount_starts = Number(this.discountRange[i].discount_starts)
        }
        if(typeof(this.discountRange[i].discount_ends)== "string"){
          this.discountRange[i].discount_ends = Number(this.discountRange[i].discount_ends)
        }
        if(typeof(this.discountRange[i].start_ratio)== "string"){
          this.discountRange[i].start_ratio = Number(this.discountRange[i].start_ratio)
        }
        if(typeof(this.discountRange[i].end_ratio)== "string"){
          this.discountRange[i].end_ratio = Number(this.discountRange[i].end_ratio)
        }
        if(this.discountRange[i].discount_starts >= this.discountRange[i].discount_ends){
          alert("할인 시작 가격이 종료 가격보다 클수없습니다.");
          return;
        }
        if(this.discountRange[i].discount_ends > this.item.goods_price){
          alert("할인범위 금액이 판매금액보다 클수없습니다.");
        }
        if(this.discountRange[i].discount_starts > this.item.goods_price){
          alert("할인범위 금액이 판매금액보다 클수없습니다.");
        }
      }

      let managerInfo = localStorage.getItem('userInfo');
      if (managerInfo) {
        managerInfo = JSON.parse(managerInfo);
      }
      const frm = new FormData();
      frm.append('site_code', managerInfo.site_code);
      frm.append('goods_name', this.item.goods_name);
      frm.append('goods_thumbnail', this.item.goods_thumbnail);
      frm.append('goods_img', this.item.goods_img);
      frm.append('goods_img2', this.item.goods_img2);
      if (this.item.detail_images) {
        for (let i = 0; i < this.item.detail_images.length; i++) {
          frm.append('detail_images', this.item.detail_images[i]);
        }
      }

      frm.append('goods_price', this.item.goods_price);
      frm.append('goods_type', this.item.goods_type);
      frm.append('goods_qty', this.item.goods_qty);
      frm.append('max_qty', this.item.max_qty);
      frm.append('start_date', this.item.start_date);
      frm.append('end_date', this.item.end_date);
      frm.append('discount_type', this.item.discount_type);
      frm.append('delivery_type', this.item.delivery_type);
      frm.append('discount_range', JSON.stringify(this.discountRange));
      nodeApi.postGoods(frm).then( res => {
        console.log(['goods hash ', res.data.result_hash]);
        router.push("/goods/goodsList");
      }).catch( err => {
        this.$log.error(err.toString());
      });
    },
    changeDetail(e) {
      let file = e.target.files;
      this.$log.debug(['file', file]);
      if (file && file.length > 0) {
        this.detailFile = '';
        this.detailImages = [];
        for (let i = 0; i < file.length; i++) {
          this.detailFile += file[i].name;
          this.item.detail_images.push(file[i]);
          if (i < file.length-1) this.detailFile += ',';
          const reader = new FileReader();
          reader.onload = e => {
            this.detailImages.push(e.target.result);
          };
          reader.readAsDataURL(file[i]);
        }
      }
    },
    decrement() {
      if (this.counter > 1) this.counter--;
    },
    onFileChange(e) {
      var files = e.target.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      var reader = new FileReader();
      reader.onload = e => {
        this.image.push(e.target.result);
      };
      reader.readAsDataURL(file);
    },
    removeImage: function(index) {
      console.log("index", index);
      this.image.splice(index, 1);
      //  this.image = "";
    },
    discountPlus() {
      this.$log.debug(['discountPlus', this.discountRange.length])
      let idx = this.discountRange.length + 1;
      this.discountRange.push({discount_starts: '', discount_ends: '', discount_qtys: '', start_ratio: 0, end_ratio:0});
    },
    discountMinus(idx) {
      this.$log.debug(['discountMinus', idx, this.discountRange.length])
      this.discountRange.splice(this.discountRange.length-1,1);
      // this.discountRange.sort()
    },
    changeMain(e) {
      this.$log.debug(['changeMain', e]);
      let file = e.target.files;

      this.$log.debug(['file', file, file[0].name]);
      this.mainFile = file[0].name;
      this.item.goods_thumbnail = file[0];
      const reader = new FileReader();
      reader.onload = e => {
        this.showMain = e.target.result;

      };
      reader.readAsDataURL(file[0]);
    },
    changeImg(e) {
      this.$log.debug(['changeImg', e]);
      let file = e.target.files;

      this.$log.debug(['file', file, file[0].name]);
      this.goods_img_name = file[0].name;
      this.item.goods_img = file[0];
      const reader = new FileReader();
      reader.onload = e => {
        this.showImg = e.target.result;

      };
      reader.readAsDataURL(file[0]);
    },
    changeImg2(e) {
      this.$log.debug(['changeImg2', e]);
      let file = e.target.files;

      this.$log.debug(['file', file, file[0].name]);
      this.goods_img2_name = file[0].name;
      this.item.goods_img2 = file[0];
      const reader = new FileReader();
      reader.onload = e => {
        this.showImg2 = e.target.result;

      };
      reader.readAsDataURL(file[0]);
    },
    discountTypeFn(type){
      if(type == 1){
        this.discount_type1 =  true;
        this.discount_type2 =  false;
      }else if(type == 2){
        this.discount_type1 =  false;
        this.discount_type2 =  true;
      }
    },
    discountRangeFn(event, index, type){
      console.log(event.target.value);

      console.log(index);
      console.log(this.discountRange);
      console.log(this.item.goods_price);

      if(this.item.goods_price== 0 || this.item.goods_price== ''){
        alert("판매가격을 입력해주세요.");
      }else{
        console.log(["else",this.item.goods_price]);

        if(type == 'discount_starts'){
          // this.discountRange[index].start_ratio = ;
          this.discountRange[index].end_ratio = Math.round(100 - (event.target.value / this.item.goods_price * 100))
        }else if(type == 'discount_ends'){
          this.discountRange[index].start_ratio = Math.round(100 - (event.target.value / this.item.goods_price * 100))
        }else if(type == 'start_ratio'){
          this.discountRange[index].discount_ends = Math.round(this.item.goods_price * (100 - event.target.value) / 100);
        }else if(type == 'end_ratio'){
          this.discountRange[index].discount_starts = Math.round(this.item.goods_price * (100 - event.target.value) / 100);
        }
      }
    },
    goodsPriceFn(event){
      for(let i = 0; i <= this.discountRange.length; i++){
        console.log(this.discountRange[i].start_ratio);
        console.log(this.discountRange[i].end_ratio);
        if( this.discountRange[i].start_ratio != 0){
          this.discountRange[i].discount_ends = Math.round(this.item.goods_price * (100 - this.discountRange[i].start_ratio) / 100);
        }
        if( this.discountRange[i].end_ratio != 0){
          this.discountRange[i].discount_starts = Math.round(this.item.goods_price * (100 - this.discountRange[i].end_ratio) / 100);
        }
      }
    }
  }
};
</script>

<style scoped>
 .ck-content { height:500px; }
 .icon{
position: absolute;
  top: 8px;
  right: 16px;
 }
 .show-img img { width: 100%; }
</style>
