<template>
  <div>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="이벤트관리" :title="item.event_name ? '이벤트 수정' : '이벤트 등록'" />
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <!-- 이벤트 제목, 이벤트 기간 -->
              <div class="row product-adding">
                <div class="col-xl-6  needs-validation">
                  <div class="form-group">
                    <label
                      for="event_name"
                      class="col-form-label pt-0"
                    ><span>*</span> 이벤트제목 <small class="text-muted">사용자에게 보여지는 이벤트 제목입니다.</small></label
                    >
                    <ValidationProvider
                      name="이벤트제목"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <input
                        class="form-control"
                        id="event_name"
                        type="text"
                        v-model="item.event_name"
                        required=""
                      />
                      <span
                        class="block text-danger text-xs absolute bottom-0 left-0"
                      >{{ errors[0] }}</span
                      >
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-xl-6  needs-validation">
                  <div class="form-group">
                    <label class="form-label d-inline"><span>*</span> 이벤트 기간
                    </label>
                    <div class="input-daterange form-inline">
                      <ValidationProvider name="이벤트시작일" rules="required" v-slot="{ errors }" >
                        <date-picker v-model="item.start_date" format="YYYY.MM.DD" type="date" valueType="format" />
                      </ValidationProvider>
                      <!--                      <input type="text" class="form-control d-inline" name="start_date" id="startDate" readonly required />-->
                      <span class="mx-2 bg-white">~</span>
                      <date-picker v-model="item.end_date" format="YYYY.MM.DD" type="date" valueType="format" />
                      <!--                      <input type="text" class="form-control d-inline" name="end_date" id="endDate" readonly required />-->
                    </div>
                  </div>
                </div>
              </div>
              <!-- 이벤트 이미지, 미션이미지 영역 -->
              <div class="row product-adding">
                <div class="col-xl-6  needs-validation">
                  <div class="form-group">
                    <label class="form-label d-inline" for="adImage">이벤트 이미지(가로형:pc버전)</label><small id="adImageHelp" class="text-muted ml-2">최적화 사이즈 : 2740 * 744, 권장 포맷 : png, jpg</small>
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" name="event_image" accept="image/*" required @change="imageMissionPreview">
                      <label class="custom-file-label">{{mission_file_name}}</label>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6  needs-validation">
                  <div class="form-group">
                    <span class="form-label">이미지 미리보기</span>

                  </div>
                  <section class="character-twin text-center">
                    <div id="sliceWrap" class="slice-wrap">

                    </div>
                    <div id="showImg" class="text-center" >
                      
                      <img v-if="previewMission" :src="previewMission" class="img-fluid" style="max-height: 150px;">
                    </div>
                  </section>
                </div>
              </div>
              <!-- 이벤트 이미지, 미션이미지 영역 -->
              <div class="row product-adding">
                <div class="col-xl-6  needs-validation">
                  <div class="form-group">
                    <label class="form-label d-inline" for="adImage">이벤트 이미지(세로형:모바일)</label><small id="adImageHelp" class="text-muted ml-2">최적화 사이즈 : 672 * 1000, 권장 포맷 : png, jpg</small>
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" name="event_image" accept="image/*" required @change="imageMission2Preview">
                      <label class="custom-file-label">{{mission2_file_name}}</label>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6  needs-validation">
                  <div class="form-group">
                    <span class="form-label">이미지 미리보기</span>

                  </div>
                  <section class="character-twin text-center">
                    <div id="sliceWrap" class="slice-wrap">

                    </div>
                    <div id="showImg2" class="text-center" >
                      
                      <img v-if="previewMission2" :src="previewMission2" class="img-fluid" style="max-height: 150px;">
                    </div>
                  </section>
                </div>
              </div>
              <!-- 미션이미지 영역 -->
              <div class="row product-adding">
                <div class="col-xl-6  needs-validation">
                  <div class="form-group">
                    <label class="form-label d-inline" for="adImage">미션이미지</label>
                   <small id="adImageHelp" class="text-muted ml-2">최적화 비율 : 13:9 , 권장 포맷 : png, jpg</small>
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" name="event_image" id="adImage" accept="image/*" required @change="imagePreview">
                      <label class="custom-file-label">{{file_name}}</label>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6  needs-validation">
                  <div class="text-center" >
                    <img v-if="preview" :src="preview" class="img-fluid" style="max-height: 150px;">
                  </div>
                </div>
              </div>
              <!-- 이밴트 상세이미지 영역 -->
              <div class="row product-adding">
                <div class="col-xl-6  needs-validation">
                  <div class="form-group">
                    <label class="form-label d-inline" for="adImage">이벤트 설명이미지</label>
                    <!--                    <small id="adImageHelp" class="text-muted ml-2">최적화 사이즈 : 600 * 672, 권장 포맷 : png, jpg</small>-->
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" name="event_image" accept="image/*" required @change="imageDetailPreview">
                      <label class="custom-file-label">{{detail_file_name}}</label>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6  needs-validation">
                  <div class="text-center" >
                    <img v-if="previewDetail" :src="previewDetail" class="img-fluid" style="max-height: 150px;">
                  </div>
                </div>
              </div>
              <div class="row product-adding">
                <div class="col-xl-6  needs-validation">

                  <div class="form-group">
                    <label class="form-label">기본 설명</label>
                    <textarea class="form-control" rows="4" v-model="item.event_desc"></textarea>
                  </div>

                  <div class="form-group">
                    <label class="form-label">보상 유형</label>
                    <label class="custom-control custom-radio custom-control-inline">
                      <input type="radio" class="custom-control-input" name="reward_type" value="REWARD_TYPE01" v-model="item.reward_type" @change="resetReward">
                      <span class="custom-control-label">동일보상형</span>
                    </label>
                    <label class="custom-control custom-radio custom-control-inline">
                      <input type="radio" class="custom-control-input" name="reward_type" value="REWARD_TYPE02" v-model="item.reward_type" @change="resetReward">
                      <span class="custom-control-label">차등보상형</span>
                    </label>
                    <!-- 2022-12-21 택일형은 현재 필요없어서 삭제 -->
                    <!-- <label class="custom-control custom-radio custom-control-inline">
                      <input type="radio" class="custom-control-input" name="reward_type" value="REWARD_TYPE03" v-model="item.reward_type" @change="resetReward">
                      <span class="custom-control-label">택일형</span>
                    </label> -->
                    <small class="form-text text-muted">동일보상형 : 지정 순위까지 동일한 보상 지급</small>
                    <small class="form-text text-muted">차등보상형 : 각 단계별 보상을 설정합니다.</small>
                    <!-- <small class="form-text text-muted">택일형 : 보상중 하나를 선택합니다.</small> -->
                    <small class="form-text text-muted">보상지급품목 이미지 최적화 비율 : 13:9 , 권장 포맷 : png, jpg</small>
                    
                  </div>
                </div>
                <div class="col-xl-6">
                </div>
              </div>

              
              <div class="row product-adding">
                <div class="col-xl-6  needs-validation">
                  <div class="form-group">
                    <label class="form-label d-inline">보상지급품목</label><small id="EventBlockHelp" class="form-text text-muted d-inline ml-2">보상지급 순위와 보상품목을 입력하세요. 순위는 숫자만 입력하세요.</small>
                    <span v-if="item.reward_type !== 'REWARD_TYPE01'" @click="rewardPlus" class="d-inline-flex" >
                      <i class="fa fa-plus-circle fa-lg text-primary " ref="rewardPlus"></i>
                    </span>
                  </div>
                  <div class="form-group">
                    <small class="form-text text-muted">보상방법</small>
                    <small class="form-text text-muted">직접 : 사용자와 판매자가 직접 만나서 지갑간거래</small>
                    <small class="form-text text-muted">쿠폰 : 디지털 쿠폰으로 발행 - 사용자가 요청시 바로 교환</small>
                    <small class="form-text text-muted">배송 : 사용자가 요청시 상품을 배송받는경우 - 사용자가 배송지 넣고 요청 후 판매자가 배송</small>
                    <small class="form-text text-muted">토큰 : 토큰으로 발행 - 사용자가 요청시 해당 토큰 바로 지급</small>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <span class="form-label">보상지급품목 미리보기</span>
                  </div>
                </div>
              </div>

              <!-- 동일 보상형 -->
              <div class="row product-adding" v-if="!item.reward_type || item.reward_type === 'REWARD_TYPE01'">
                <div class="col-xl-6  needs-validation">
                  <div class="form-group">
                    <div id="rewardSame" class="row" v-if="!item.reward_type || item.reward_type === 'REWARD_TYPE01'">
                      <div class="col-3">
                        <select class="form-control" name="reward_item_type" required v-model="item.reward_item_type">
                          <option value="">보상 방법</option>
                          <option value="REWARD_ITEM_TYPE01">직접</option>
                          <option value="REWARD_ITEM_TYPE02">쿠폰</option>
                          <option value="REWARD_ITEM_TYPE03">배송</option>
                          <option value="REWARD_ITEM_TYPE04">토큰</option>
                        </select>
                      </div>
                      <div class="col-4">
                        <input type="number" class="form-control form-inline" id="rankStart" name="rank_start" placeholder="보상 지급 순위" required v-model="item.rank_end">
                      </div>
                      <div class="col-5">
                        <input type="text" class="form-control form-inline" id="rewardItem" name="reward_item" placeholder="보상 품목" required v-model="item.reward_item">
                      </div>
                      
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="custom-file">
                      <input id="file0" type="file" class="custom-file-input" name="event_image" accept="image/*" required @change="rewardImageUpload(0)">
                      <label class="custom-file-label">{{rewardImages[0].file_name}}</label>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <section class="character-twin text-center" >
                      <div id="rewardImage0" class="text-center" >
                        <img  :src="rewardImages[0].file_path" class="img-fluid" style="max-height: 150px;">
                      </div>
                    </section>
                  </div>
                </div>
              </div>

              <!-- 차등보상형 -->
              <div id="rewardStep"  v-if="item.reward_type === 'REWARD_TYPE02'">
                <div class="row product-adding" v-for="(data, index) in rewardSteps" v-bind:key="index">
                  <div class="col-xl-6  needs-validation">
                    <div class="input-group mb-1">
                      <span class="input-group-prepend">
                        <span class="input-group-text">{{ index+1 }}</span>
                      </span>
                      <select class="form-control form-inline col-2" name="reward_item_type[]" required v-model="data.reward_item_type">
                          <option value="" selected>보상 방법</option>
                          <option value="REWARD_ITEM_TYPE01">직접</option>
                          <option value="REWARD_ITEM_TYPE02">쿠폰</option>
                          <option value="REWARD_ITEM_TYPE03">배송</option>
                          <option value="REWARD_ITEM_TYPE04">토큰</option>
                        </select>
                      <input type="number" class="form-control form-inline col-2" name="rank_starts[]" placeholder="시작 순위" required v-model="data.rank_starts">
                      <input type="number" class="form-control form-inline col-2" name="rank_ends[]" placeholder="종료 순위" required v-model="data.rank_ends">
                      <input type="text" class="form-control form-inline" name="reward_items[]" placeholder="보상 품목" required v-model="data.reward_items">
                      <span class="input-group-append">
                        <span class="input-group-text" @click="rewardMinus(item, data, index)">
                          <i class="fa fa-lg" :class="index > 0 ? 'fa-minus-circle text-danger' : 'fa-check-circle' " aria-hidden="true"></i>
                        </span>
                      </span>
                    </div>
                    <div class="form-group">
                      <div class="custom-file">
                        <input :id="'file'+index" type="file" class="custom-file-input" name="event_image" accept="image/*" required @change="rewardImageUpload(index)">
                        <label class="custom-file-label">{{rewardImages[index].file_name}}</label>
                      </div>
                    </div>

                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <div :id="'rewardImage'+index" class="text-center" >
                        <img :src="rewardImages[index].file_path" class="img-fluid"  style="max-height: 150px;">
                      </div>
                    </div>
                  </div>
                </div>    
              </div>

              <!-- 택일형 -->
              <!-- <div id="rewardSelect" v-if="item.reward_type === 'REWARD_TYPE03'">
                <div class="row product-adding" v-for="(data, index) in rewardSelects"  v-bind:key="index">
                  <div class="col-xl-6  needs-validation">
                    <div class="input-group mb-1">
                      <span class="input-group-prepend">
                        <span class="input-group-text">-</span>
                      </span>
                      <input type="text" class="form-control form-inline" name="selected_items[]" placeholder="보상 품목" required v-model="data.selected_items">
                      <input type="text" class="form-control form-inline col-2" name="selected_ends[]" placeholder="수량" required v-model="data.selected_ends">
                      <span class="input-group-prepend">
                        <span class="input-group-text">개</span>
                      </span>
                      <span class="input-group-append">
                        <span class="input-group-text" @click="rewardMinus(item, data, index)">
                          <i class="fa fa-lg" :class="index > 1 ? 'fa-minus-circle text-danger' : 'fa-check-circle' " aria-hidden="true"></i>
                        </span>
                      </span>
                    </div>
                    <div class="form-group">
                      <div class="custom-file">
                        <input :id="'file'+index" type="file" class="custom-file-input" name="event_image" accept="image/*" required @change="rewardImageUpload(index)">
                        <label class="custom-file-label">{{rewardImages[index].file_name}}</label>
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-6">
                    <div class="form-group">
                      <div :id="'rewardImage'+index" class="text-center" >
                        <img :src="rewardImages[index].file_path" class="img-fluid"  style="max-height: 150px;">
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->

              <!-- 이벤트 조각 -->
              <div class="row form-group">
                <div class="col-6 d-none">
                  <div class="form-group">

                  </div>
                  <label class="form-label">수집 유형</label>
                  <label class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" name="event_type" value="event_type01" v-model="item.event_type">
                    <span class="custom-control-label">일반수집형</span>
                  </label>
                  <label class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" name="event_type" value="event_type02" v-model="item.event_type">
                    <span class="custom-control-label">퀘스트수집형</span>
                  </label>
                  <small class="form-text text-muted">일반수집형 : 동일한 가중치의 일반적인 수집</small>
                  <small class="form-text text-muted">퀘스트수집형 : 퀘스트별 가중치를 부여하여 수집</small>
                </div>
                <div class="col-6">
                  <div class="form-group">

                  </div>
                  <label class="form-label d-inline" for="blockCount">조각수</label><small class="text-muted ml-2">사용자가 수집할 블록수를 선택하세요.</small>
                  <select class="form-control" id="blockCount" name="block_count" required v-model="item.block_count" @change="changCount">
                    <option value="">조각수를 선택하세요</option>
                    <option v-for="i in 9"  v-bind:key="i">{{i}}</option>
                  </select>
                </div>
              </div>
              <div class="row form-group" id="questBlock" v-if="questBlocks.length > 0">
                <div class="col-6" v-for="(data, index) in questBlocks"  v-bind:key="index">
                   <div class="card mb-1">
                        <div class="card-body p-3">
                              <div class="card-title d-inline">
                                    <label class="text-primary">블록 {{index+1}}</label>
                                    <div class="d-inline-flex input-group input-group-sm mb-1 float-right w-auto">
                                      <span class="input-group-prepend">
                                          <span class="input-group-text">수집방법</span>
                                          <select class="form-control form-control-sm form-control block_type" name="block_type" required v-model="data.block_type">
                                              <option value="" selected>선택</option>
                                              <option value="BLOCK_TYPE01">QR코드</option>
                                              <option value="BLOCK_TYPE02">링크</option>
                                              <option value="BLOCK_TYPE08">Youtube</option>
<!--                                               <option value="BLOCK_TYPE03">GPS</option>-->
<!--                                               <option value="BLOCK_TYPE04">QR+GPS</option>-->
                                              <option value="BLOCK_TYPE05">공유하기</option>
                                              <option value="BLOCK_TYPE09">문서</option>
                                              <option value="BLOCK_TYPE10">업로드</option>
                                          </select>
                                      </span>
                                      <span class="input-group-prepend ml-2 is-direct" v-if="data.block_type === 'BLOCK_TYPE02' || data.block_type === 'BLOCK_TYPE05'">
                                          <span class="input-group-text ">즉시수집</span>
                                          <div class="input-group-text bg-white">
                                            <b-form-checkbox switch v-model="data.is_direct" ></b-form-checkbox>
                                              <h4 class="mdi mdi-information-outline ml-2" data-toggle="popover" data-content="즉시수집 사용 시 힌트 확인 후 즉시 블록이 수집됩니다."></h4>
                                          </div>
                                      </span>
                                      <span class="input-group-prepend ml-2 is-direct" v-if="data.block_type === 'BLOCK_TYPE09'">
                                          <span class="input-group-text ">기준</span>
                                          <select class="form-control form-control-sm form-control block_type" name="etc1" required v-model="data.etc1">
                                              <option value="" selected>선택</option>
                                              <option value="ETC_TYPE01">등록증</option>
                                              <option value="ETC_TYPE02">특허명세서</option>
                                              <option value="ETC_TYPE03">BM</option>
                                              <option value="ETC_TYPE04">추천인</option>
                                              <!-- <option value="ETC_TYPE05">거래가격</option>
                                              <option value="ETC_TYPE06">연간수수료</option> -->
                                          </select>
                                      </span>
                                      <span class="input-group-prepend ml-2 is-direct" v-if="data.block_type === 'BLOCK_TYPE10'">
                                          <span class="input-group-text ">자동승인</span>
                                          <div class="input-group-text bg-white">
                                            <b-form-checkbox switch v-model="data.is_direct" ></b-form-checkbox>
                                              <h4 class="mdi mdi-information-outline ml-2" data-toggle="popover" data-content="자동승인 사용 시 사용자가 업로드 후 즉시 블록이 수집됩니다."></h4>
                                          </div>
                                      </span>
                                  </div>
                                </div>
                              <div class="input-group input-group-sm mb-1">
                                    <span class="input-group-prepend">
                                      <span class="input-group-text" v-if="data.block_type != 'BLOCK_TYPE09'">블록설명</span>
                                      <span class="input-group-text" v-if="data.block_type === 'BLOCK_TYPE09'">설명</span>
                                  </span>
                                    <input type="text" name="level_explains[]" class="form-control form-control-sm" placeholder="ex)홈페이지, 유튜브, 블로그등의 설명.. (입력 시 힌트로 표시됩니다.)" required v-model="data.level_explains">

                                </div>
                              <div class="input-group input-group-sm mb-1 explain-container" v-if="data.block_type === 'BLOCK_TYPE09' && (data.etc1 === 'ETC_TYPE01' || data.etc1 === 'ETC_TYPE02' || data.etc1 === 'ETC_TYPE03' || data.etc1 === 'ETC_TYPE04') ">
                                    <span class="input-group-prepend">
                                      <span class="input-group-text" v-if="data.etc1 === 'ETC_TYPE01' || data.etc1 === 'ETC_TYPE02'">권리자</span>
                                      <span class="input-group-text" v-if="data.etc1 === 'ETC_TYPE03' || data.etc1 === 'ETC_TYPE04'">인증</span>
                                      <span class="input-group-text" v-if="data.etc1 === 'ETC_TYPE05' || data.etc1 === 'ETC_TYPE06'">비율</span>
                                  </span>
                                    <input type="text" name="etc2[]" class="form-control form-control-sm" placeholder="" v-model="data.etc2">
                              </div>
                              <div class="input-group input-group-sm mb-1 explain-container" v-if="data.block_type === 'BLOCK_TYPE09' && (data.etc1 === 'ETC_TYPE01' || data.etc1 === 'ETC_TYPE02' || data.etc1 === 'ETC_TYPE03' || data.etc1 === 'ETC_TYPE04')">
                                <!-- <div class="custom-file" style="height:35px;">
                                  
                                  <input type="file" class="custom-file-input" required style="height:35px;" @change="fileUpload" name="etc2[]"  >
                                  <input type="text" name="etc2[]" style="display: none;" v-model="data.etc2">
                                  
                                  <label class="custom-file-label" >등록할 파일 선택</label>
                                </div> -->
                                <!-- <b-form-file
                                  name="etc2[]"
                                  v-model="data.etc2"
                                  :state="Boolean(data.etc2)"
                                  placeholder="첨부 문서를 선택해 주세요."
                                  drop-placeholder="Drop file here..."
                                  @change="fileuploads"
                                ></b-form-file> -->
                                <b-form-file
                                  name="etc3[]"
                                  v-model="data.etc3"
                                  
                                  placeholder="첨부 문서를 선택해 주세요."
                                  drop-placeholder="Drop file here..."
                                ></b-form-file>
                              </div>
                              <div class="input-group input-group-sm mb-1 explain-container" v-if="data.block_type === 'BLOCK_TYPE02'">
                                    <span class="input-group-prepend">
                                      <span class="input-group-text">수집대상 URL</span>
                                  </span>
                                    <input type="text" name="etc_explains[]" class="form-control form-control-sm" placeholder="ex)https://www.abc.com." v-model="data.etc_explain">
                              </div>
                              <div class="gps-container" style="display: none">
                                   <div class="input-group input-group-sm mb-1 ">
                                         <button type="button" class="btn btn-sm btn-outline-primary zip-search">주소검색</button>
                                         <input type="text" name="address[]" class="form-control form-control-sm address" readonly>
                                     </div>
                                   <div class="input-group input-group-sm mb-1 ">
                                         <span class="input-group-prepend">
                                         <span class="input-group-text">위도</span>
                                     </span>
                                         <input type="text" class="form-control form-control-sm latitude" placeholder="위도" name="latitude[]">
                                         <span class="input-group-prepend">
                                         <span class="input-group-text">경도</span>
                                     </span>
                                         <input type="text" class="form-control form-control-sm longitude" placeholder="경도" name="longitude[]">
                                         <span class="input-group-prepend">
                                         <span class="input-group-text">허용범위</span>
                                     </span>
                                         <input type="text" class="form-control form-control-sm col-2" placeholder="m단위" name="range[]">
                                         <span class="input-group-append">
                                         <span class="input-group-text">M</span>
                                     </span>
                                     </div>
                                </div>
                              <div class="youtube-container" v-if="data.block_type === 'BLOCK_TYPE08'">
                                   <div class="input-group input-group-sm mb-1 ">
                                         <span class="input-group-prepend">
                                         <span class="input-group-text">YouTube 공유주소</span>
                                     </span>
                                         <input type="text" class="form-control form-control-sm " placeholder="ex) https://youtu.be/M7lc1UVf-VE" name="youtube_id[]" v-model="data.youtube_id">
                                         <span class="input-group-prepend">
                                         <span class="input-group-text">수집 목표시간(초)</span>
                                     </span>
                                         <input type="number" class="form-control form-control-sm col-2" placeholder="숫자만 입력" name="goal_sec[]" v-model="data.goal_sec">
                                         <span class="input-group-prepend">
                                         <span class="input-group-text">초</span>
                                     </span>
                                     </div>
                                </div>
<!--                              <div class="input-group input-group-sm mb-1 ">-->
<!--                                    <span class="input-group-prepend">-->
<!--                                      <span class="input-group-text ">블록 수집 후 광고를 띄우겠습니까?</span>-->
<!--                                      <div class="input-group-text bg-white">-->
<!--                                        <b-form-checkbox v-model="checked" name="check-button" switch>-->
<!--                                          Switch Checkbox <b>(Checked: {{ checked }})</b>-->
<!--                                        </b-form-checkbox>-->
<!--                                          <label class="switch switch-icon switch-outline-alt-primary form-control-label m-0">-->
<!--                                              <input type="checkbox" name="is_adi+" class="switch-input form-check-input form-control-sm ad-term-check" value="1">-->
<!--                                              <span class="switch-label"></span>-->
<!--                                              <span class="switch-handle"></span>-->
<!--                                          </label>-->
<!--                                      </div>-->
<!--                                  </span>-->
<!--                                    <span class="input-group-prepend use-pool" style="display: none">-->
<!--                                      <span class="input-group-text ">광고 풀 사용</span>-->
<!--                                      <div class="input-group-text bg-white">-->
<!--                                          <label class="switch switch-icon switch-outline-alt-primary form-control-label m-0">-->
<!--                                              <input type="checkbox" name="use_pooli+" class="switch-input form-check-input form-control-sm use-pool-check" value="1">-->
<!--                                              <span class="switch-label"></span>-->
<!--                                              <span class="switch-handle"></span>-->
<!--                                          </label>-->
<!--                                      </div>-->
<!--                                  </span>-->
<!--                                </div>-->
                              <div class="ad-term" style="display: none">
                                    <div class="input-group input-group-sm mb-1 ">
                                          <span class="input-group-prepend">
                                          <span class="input-group-text">블록 광고 타이틀</span>
                                      </span>
                                          <input type="text" name="ad_title[]" class="form-control form-control-sm" placeholder="광고 팝업 제목을 보여줍니다.(미 작성 시 이미지만 노출됨)" required>
                                      </div>
                                    <div class="input-group input-group-sm mb-1 ">
                                          <span class="input-group-prepend">
                                          <span class="input-group-text">블록 광고 이미지</span>
                                      </span>
                                          <div class="custom-file">
                                                <input type="file" name="ad_img[]" class="custom-file-input interval-file" required>
                                                <label class="custom-file-label">광고 이미지 선택</label>
                                            </div>
                                      </div>
                                    <div class="input-group input-group-sm mb-1 ">
                                          <span class="input-group-prepend">
                                          <span class="input-group-text">이동 URL</span>
                                      </span>
                                          <input type="text" name="ad_url[]" class="form-control form-control-sm" placeholder="이미지 클릭 시 이동할 URL - http, https 형식으로 입력">
                                      </div>
                                </div>
                              <div class="ad-pool" style="display: none">
                                    <div class="input-group input-group-sm mb-1">
                                          <span class="input-group-prepend">
                                          <span class="input-group-text ">노출방법</span>
                                          <div class="input-group-text bg-white">
                                              <div class="form-check form-check-inline">
                                                  <input class="form-check-input form-control-sm view_type" type="radio" name="view_type" id="viewType1i+" value="VIEW_TYPE01" checked>
                                                  <label class="form-check-label form-check-label-small" for="viewType1i+">단일 광고 노출</label>
                                              </div>
                                          </div>
                                          <div class="input-group-text bg-white">
                                              <div class="form-check form-check-inline">
                                                  <input class="form-check-input form-control-sm view_type" type="radio" name="view_type" id="viewType2i+" value="VIEW_TYPE02">
                                                  <label class="form-check-label text-sm-left" for="viewType2i+">분류 내 광고 랜덤 노출</label>
                                              </div>
                                          </div>
                                       </span>
                                           <span class="input-group-prepend">
                                           <div class="input-group-text bg-white">
                                               <input type="button" class="btn btn-sm btn-outline-primary choose-pool" value="선택하기">
                                           </div>
                                       </span>
                                      </div>
                                    <div class="input-group input-group-sm mb-1">
                                          <span class="" id="choose-title"></span>
                                      </div>
                                </div>
                           </div>
                     </div>
                </div>
              </div>
              <div class="row form-group">
                <div class="col">
                  <label class="form-label d-inline" for="">토근지급 방식</label>
                  <label class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" name="token_method" value="token_method01" checked="" v-model="item.token_method" @change="tokenMethodFn">
                    <span class="custom-control-label">참여완료자 균등 분배</span>
                  </label>
                  <label class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" name="token_method" value="token_method02" v-model="item.token_method" @change="tokenMethodFn">
                    <span class="custom-control-label">참여완료자별 정액 지급</span>
                  </label>
                  <small id="typeNormalHelp" class="form-text text-muted">참여완료자 균등 분배 : 블록 수집완료한 사용자에게 전체비용을 균등하게 분배함</small>
                  <small id="typeQuestHelp" class="form-text text-muted">참여완료자별 정액 지급 : 지정한 토큰을 블록 수집완료한 사용자에게 지급함</small>
                </div>
              </div>
              <div id="methos01">
                <div class="row form-group" v-if="item.token_method=='token_method02'">
                  <div class="col-6" >
                    <label class="form-label d-inline" for="">목표 인원수</label>
                    <input type="number" class="form-control" id="targetNumber" name="target_number" aria-describedby="adNameHelp" placeholder="목표인원수" required v-model="item.target_number" @keyup="targetNumber">
                  </div>
                  <div class="col-6">
                    <label class="form-label d-inline" for="">인원별 토큰 지급액</label>
                    <div class="input-group mb-1">
                      <span class="input-group-prepend">
                          <span class="input-group-text">₩</span>
                      </span>
                      <input type="number" class="form-control" id="tokenPayment" name="token_payment" placeholder="인원별 토큰 지급액 - 숫자만 입력" required v-model="item.token_payment" @keyup="targetNumber">
                    </div>
                  </div>
                </div>
                <div class="row form-group">
                  <div id="amountArea" class="col-6">
                    <label class="form-label d-inline" for="">결제 예정 금액</label><small id="" class="form-text text-muted d-inline ml-2" v-if="item.token_method=='token_method02'">참여완료자별 정액 지급은 목표인원수 * 인원별 토큰 지급액 자동계산</small>
                    <div class="input-group mb-1">
                      <span class="input-group-prepend">
                          <span class="input-group-text">₩</span>
                      </span>
                      <input type="number" class="form-control" id="payAmount" name="payment" required v-model="item.payment" :readonly="paymentRead">
                    </div>
                  </div>
                  <!-- <div id="amountArea" class="col-6">
                    <label class="form-label d-inline" for="">결제 지갑 주소</label>
                    <small id="" class="form-text text-muted d-inline ml-2">이벤트 생성시 결제예정금액만큼 결제되는 주소</small>
                    
                    <div class="input-group mb-1">
                      <input type="text" class="form-control" id="walletAddress" name="walletAddress" required v-model="item.walletAddress" @change="resetConfirm">
                    </div>
                  </div> -->
                </div>
              </div>
              <div class="text-center">
                <button type="button" class="btn btn-primary btn-pill mr-1" id="btn-save" @click="eventSave">이벤트생성</button>
                <router-link to="/events/eventList"><button type="button" class="btn btn-danger btn-pill ml-1" data-dismiss="modal">돌아가기</button></router-link>
<!--                <div class="text-muted mt-2">{{$site->app_url}}에 게시됩니다.</div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ko';
import nodeApi from "../../api/nodeApi";
import router from "../../router";

export default {
  components: {DatePicker},
  data() {
    return {
      counter: 0,
      content: "<h1>Some initial content</h1>",
      image: [],
      paymentRead: false,
      item: {
        event_name : '',
        event_desc : '',
        reward_type : 'REWARD_TYPE01',
        event_type : 'event_type01',
        token_method : 'token_method01',
        token_payment : 0,
        payment : 0,
        rank_start : '',
        rank_end : '',
        reward_item : '',
        block_count : '',
        start_date : '',
        end_date : '',
        reward_item_type : '',
        target_number : 0,
        walletAddress : '',
        payment_confirm : true
      },
      rewardMax:9,
      rewardSteps : [{rank_starts: '', rank_ends: '', reward_items : '', reward_item_type : ''}],
      rewardSelects : [{reward_items : '', reward_qtys: ''}, {reward_items : '', reward_qtys: ''}],
      rewardImages : [{idx:0, file_name:'원본 이미지 파일 선택', file_path:'', file:''}],
      questBlocks : [],
      preview: '',
      file_name: '원본 이미지 파일 선택',
      previewMission: '',
      mission_file_name: '원본 이미지 파일 선택',
      previewMission2: '',
      mission2_file_name: '원본 이미지 파일 선택',
      previewDetail: '',
      detail_file_name: '원본 이미지 파일 선택',
    };
  },
  methods: {


    eventSave() {
      this.$log.debug(['eventSave', this.item, this.questBlocks]);
      if(this.item.event_name == ''){
        alert("이벤트 제목을 입력해주세요.");
        return;
      }
      if(this.item.start_date == '' || this.item.end_date == ''){
        alert("이벤트 기간을 입력해주세요.");
        return;
      }

      if(!this.item.payment_confirm){
        alert("잔고 확인을 해주세요.");
        return;
      }
      

      let managerInfo = localStorage.getItem('userInfo');
      if (managerInfo) {
        managerInfo = JSON.parse(managerInfo);
      }

      const frm = new FormData();

      frm.append('site_code', managerInfo.site_code);
      frm.append('event_name', this.item.event_name);
      frm.append('event_image', this.item.event_image);
      frm.append('mission_image', this.item.mission_image);
      frm.append('mission_image2', this.item.mission_image2);
      frm.append('detail_image', this.item.detail_image);
      if (this.item.detail_images) {
        for (let i = 0; i < this.item.detail_images.length; i++) {
          frm.append('detail_images', this.item.detail_images[i]);
        }
      }

      frm.append('token_method', this.item.token_method);
      frm.append('token_payment', this.item.token_payment);
      frm.append('event_desc', this.item.event_desc);
      frm.append('event_type', this.item.event_type);
      frm.append('start_date', this.item.start_date);
      frm.append('end_date', this.item.end_date);
      frm.append('target_number', this.item.target_number);
      frm.append('payment', this.item.payment);
      frm.append('reward_type', this.item.reward_type);
      frm.append('paymentAddress', this.item.walletAddress);
      if (this.item.reward_type === 'REWARD_TYPE01') {
        frm.append('rank_end', this.item.rank_end)
        frm.append('reward_item', this.item.reward_item)
        frm.append('reward_item_type', this.item.reward_item_type)
      }

      if (this.item.reward_type === 'REWARD_TYPE02') {
        frm.append('reward_steps', JSON.stringify(this.rewardSteps))
      }

      if (this.item.reward_type === 'REWARD_TYPE03') {
        frm.append('reward_selects', JSON.stringify(this.rewardSelects))
      }
      // frm.append('discount_range', JSON.stringify(this.discountRange));
      
      
      console.log(['from', frm, frm.entries()]);
      console.log(['questBlocks', this.questBlocks]);
      console.log(['questBlocks json', JSON.stringify(this.questBlocks)]);
      console.log(['image', this.image]);
      console.log(['rewardImages', this.rewardImages]);
      this.rewardImages.forEach(function(element, index, array){
        console.log(['idx : ', element.idx]);
        console.log(['file_name : ', element.file_name]);
        console.log(['file_path : ', element.file_path]);
        console.log(['file : ', element.file]);
        console.log(['index : ', index]);
        frm.append('rewardImage_'+(index+1), element.file);
      });

      console.log(['item', this.item]);
      this.questBlocks.forEach(function(element, index, array){
        console.log(['etc1 : ', element.etc1]);
        console.log(['etc2 : ', element.etc2]);
        console.log(['etc3 : ', element.etc3]);
        console.log(['index : ', index]);
        frm.append('etc3_'+(index+1), element.etc3);
      });
      console.log(['from', frm, frm.entries()]);
      frm.append('quest_blocks', JSON.stringify(this.questBlocks))
      // frm.append('quest_blocks', this.questBlocks);
      this.$log.debug(['from', frm, frm.entries()]);

      nodeApi.postEvent(frm).then( res => {
        this.$log.debug(['postEvent res', res]);
        if (res.data.success) {
          nodeApi.showToast('이벤트 등록 완료', 'success');
          console.log(['info hash ', res.data.result_hash]);
          router.push("/events/eventList");

        } else {
          nodeApi.showToast(res.data.msg);
        }

      }).catch( err => {
        this.$log.error(err.toString());
      });
    },
    onFileChange(e) {
      var files = e.target.files;
      if (!files.length) return;

      this.createImage(files[0]);
    },
    decrement() {
      if (this.counter > 1) this.counter--;
    },
    createImage(file) {
      var reader = new FileReader();
      reader.onload = e => {
        this.image.push(e.target.result);
      };
      reader.readAsDataURL(file);
    },
    removeImage: function(index) {
      console.log("index", index);
      this.image.splice(index, 1);
      //  this.image = "";
    },
    imagePreview(e) {
      const file = e.target.files[0];

      this.file_name = file.name;
      this.item.event_image = file;
      this.preview = URL.createObjectURL(file);

      e.target.value = ''; 

    },
    imageMissionPreview(e) {
      const file = e.target.files[0];

      this.mission_file_name = file.name;
      this.item.mission_image = file;
      this.previewMission = URL.createObjectURL(file);

      e.target.value = ''; 

    },
    imageMission2Preview(e) {
      const file = e.target.files[0];

      this.mission2_file_name = file.name;
      this.item.mission_image2 = file;
      this.previewMission2 = URL.createObjectURL(file);

      e.target.value = ''; 

    },
    imageDetailPreview(e) {
      const file = e.target.files[0];

      this.detail_file_name = file.name;
      this.item.detail_image = file;
      this.previewDetail = URL.createObjectURL(file);

      e.target.value = ''; 

    },
    fileuploads(e){
      this.$log.debug(['e', e]);
      console.log(['e ', e]);
      console.log(['this', this]);
      var files = e.target.files[0];

      const frm = new FormData();
      frm.append('docFile', files);
      console.log(['from', frm, frm.entries()]);

      nodeApi.fileUpload(frm).then( res => {
        this.$log.debug(['postEvent res', res]);
        console.log(['res', res]);
        if (res.data.status === 200) {
          // nodeApi.showToast('이벤트 등록 완료', 'success');
          console.log(['res suceess ']);
          // router.push("/events/eventList");

        } else {
          console.log(['res fail ']);
          // nodeApi.showToast(res.data.msg);
        }

      }).catch( err => {
        this.$log.error(err.toString());
      });
    },
    resetReward() {
      this.$log.debug(['resetReward', this.item.reward_type]);
      if(this.item.reward_type == "REWARD_TYPE03"){
        this.rewardImages = [{idx:0, file_name:'원본 이미지 파일 선택', file_path:'', file:''},{idx:1, file_name:'원본 이미지 파일 선택', file_path:'', file:''}]
      }else{
        this.rewardImages = [{idx:0, file_name:'원본 이미지 파일 선택', file_path:'', file:''}]
      }
      this.rewardSteps = [{rank_starts: '', rank_ends: '', reward_items : '', reward_item_type : ''}];
      this.rewardSelects = [{reward_items : '', reward_qtys: ''}, {reward_items : '', reward_qtys: ''}];
      this.$log.debug(this.rewardImages);
    },
    rewardPlus() {
      this.$log.debug(['rewardPlus', this.item.reward_type])
      this.$log.debug(['this.rewardSteps.length', this.rewardSteps.length])
      this.$log.debug(['this.rewardSelects.length', this.rewardSelects.length])

      if (this.item.reward_type === 'REWARD_TYPE02') {
        if(this.rewardSteps.length == this.rewardMax){
          alert("보상지급품목의 최대 개수는 "+this.rewardMax + "개입니다.");
        }else{
          this.rewardImages.push({idx:this.rewardSteps.length, file_name:'원본 이미지 파일 선택', file_path:'', file:''});
          this.rewardSteps.push({rank_starts: '', rank_ends: '', reward_items : '', reward_item_type : ''});
          console.log(this.rewardImages);
        }
      }

      if (this.item.reward_type === 'REWARD_TYPE03') {
        if(this.rewardSelects.length == this.rewardMax){
          alert("보상지급품목의 최대 개수는 "+this.rewardMax + "개입니다.");
        }else{
          this.rewardImages.push({idx:this.rewardSelects.length, file_name:'원본 이미지 파일 선택', file_path:'', file:''});
          this.rewardSelects.push({reward_items : '', reward_qtys: ''});
          console.log(this.rewardImages);
        }
      }
    },
    rewardMinus(item, data, idx) {

      this.$log.debug(['rewardMinus', item, data, idx])
      if (item.reward_type === 'REWARD_TYPE02' && idx > 0) {
        this.rewardImages.splice(this.rewardSteps.length-1,1);
        this.rewardSteps.splice(this.rewardSteps.length-1,1);
        console.log(this.rewardImages);
      }

      if (item.reward_type === 'REWARD_TYPE03' && idx > 1) {
        this.rewardImages.splice(this.rewardSelects.length-1,1);
        this.rewardSelects.splice(this.rewardSelects.length-1,1);
        console.log(this.rewardImages);
      }
      // this.discountRange.splice(this.discountRange.length-1,1);
    },
    changCount() {
      this.$log.debug(['changCount', this.item.block_count]);
      if (this.item.block_count > 0) {
        this.questBlocks = [];
        for (let i = 0; i < this.item.block_count; i++) {
          this.questBlocks.push({block_type: '', level_explains: '', is_direct:'true'});
        }
      }
    },
    targetNumber(e) {
      this.$log.debug(['targetNumber', this.item.target_number, this.item.token_payment])
      this.item.payment = this.item.target_number * this.item.token_payment;
    },
    rewardImageUpload(index){
      console.log("rewardImageUpload");
      // console.log(e);
      // console.log(typeof(e));
      console.log(index);

      const selectedFile = document.getElementById('file'+index).files[0];
      console.log(selectedFile);
      // const file = e.target.files[0];

      for(let i = 0; this.rewardImages.length >= i; i++){
        if(this.rewardImages[index].idx == index){
          let temp = URL.createObjectURL(selectedFile);
          this.rewardImages.splice(index, 1, {idx:index, file_name:selectedFile.name, file_path:temp, file:selectedFile});
          // this.rewardImages[index] = {idx:index, file_name:selectedFile.name, file_path:temp, file:selectedFile}
        }
      }
      console.log("rewardImages");
      console.log(this.rewardImages);
    },
    tokenMethodFn(){

      this.$log.debug(['token_method', this.item.token_method]);
      if("token_method02" == this.item.token_method){
        this.$log.debug(['token_method02']);
        this.paymentRead = true;
      }else if("token_method01" == this.item.token_method){
        this.$log.debug(['token_method01']);
        this.paymentRead = false;
      }
    },
    addressConfirm(){
      if(this.item.walletAddress == ''){
        alert("확인할 주소를 입력해주세요.");
        return;
      }
      const frm = new FormData();
      frm.append('address', this.item.walletAddress);
      frm.append('payment', this.item.payment);

      nodeApi.posetAddressConfirm(frm).then( res => {
        this.$log.debug(['addressConfirm res', res]);
        if (res.data.success) {
          nodeApi.showToast(res.data.msg, 'success');
          this.item.payment_confirm = true;
        } else {
          nodeApi.showToast(res.data.msg);
          this.item.payment_confirm = false;
        }

      }).catch( err => {
        this.$log.error(err.toString());
        this.item.payment_confirm = false;
      });
    },
    resetConfirm(){
      this.item.payment_confirm = false;
    }
  }
};
</script>

<style scoped>
 .ck-content { height:500px; }
 .icon{
position: absolute;
  top: 8px;
  right: 16px;
 }

 /*.mx-datepicker {*/
 /*  width: 8rem;*/
 /*}*/
</style>
