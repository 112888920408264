<template>
  <div>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="상품관리" title="주문내역" />
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
<!--            <div class="card-header">-->
<!--              <h5>Products Details</h5>-->
<!--            </div>-->
            <div class="card-body">
              <div class="col-xl-12  needs-validation">
                <div class="form-group">
                  <span>*</span> 주문 일자
                  <date-picker v-model="startDate" :value="startDate"  format="YYYY.MM.DD" type="date" valueType="format" id="startDate" ></date-picker>
                  <span class="mx-2 bg-white">~</span>
                  <date-picker v-model="endDate" :value="endDate"  format="YYYY.MM.DD" type="date" valueType="format" id="endDate" :default-value="endDate">{{ endDate }}</date-picker>
                  <span>
                    <select class="form-control" name="reward_item_type" required v-model="purchase_status" style="width: 150px; display: inline; margin-left: 10px; font-size: 14px;">
                      <option value="">상태조회</option>
                      <option value="PURCHASE_STATUS01">결제완료</option>
                      <option value="PURCHASE_STATUS02">배송준비중</option>
                      <option value="PURCHASE_STATUS03">배송중</option>
                      <option value="PURCHASE_STATUS04">배송완료</option>
                      <option value="PURCHASE_STATUS05">취소요청</option>
                      <option value="PURCHASE_STATUS06">주문취소</option>
                    </select>
                  </span>
                  <span><input  class="form-control" type="text" placeholder="상품명 검색" v-model="search" style="width: 150px; display: inline; margin-left: 10px; font-size: 14px;"/></span>
                  <button class="btn btn-primary" style="float: right;" @click="searchButton">검색</button>
                </div>
              </div>
              <!-- <b-row class="row mb-3">
                <b-col>
                  <router-link to="/goods/addGoods" class=""><b-button class="btn btn-primary" variant="primary" >주문내역</b-button></router-link>
                </b-col>
              </b-row> -->
              <div class="table-responsive datatable-vue product-physical">
                <div>
                  <b-modal id="modal-2" title="Confirmation" @ok="deleteRow(selectedSku)">
                    <p class="my-4">Are you sure!</p>
                  </b-modal>
                </div>

                <b-table
                  :select-mode="selectMode"
                  class="text-center"
                  show-empty
                  striped
                  hover
                  head-variant="light"
                  bordered
                  stacked="md"
                  :items="items"
                  :fields="tablefields"
                  :filter="filter"
                  :current-page="currentPage"
                  :per-page="perPage"
                  @filtered="onFiltered"
                  ref="selectableTable"
                  :selectable="true"
                  @row-selected="rowSelected"
                >
                  <!-- image start -->
                  <template #cell(status)="field">
                    <statusIcon :field="field"></statusIcon>
                  </template>
                  <template #cell(goods_thumbnail)="data">
                    <img
                        height="50px"
                        :src="imgUrl + data.item.goods_thumbnail"
                        width="50px"
                    />
                  </template>
                  <template #cell(goods_name)="field">
                    <a href="javascript:;" @click="selectItem(field.item)" v-b-modal.goods-view-modal>{{ field.item.goods_name }}</a>
                  </template>
                  <template #cell(date)="data">

                    {{data.item.start_date}} ~ {{data.item.end_date}}
                  </template>


                </b-table>
              </div>
              <b-col md="12" class="my-1 pagination-justify p-0">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  aria-controls="my-table"
                  class="mt-4"
                ></b-pagination>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ko';

import { mapGetters } from "vuex";
import statusIcon from "../../components/featherIcons/status-icon.vue";
import {imgUrl} from '../../constants/config';
import nodeApi from "../../api/nodeApi";
export default {
  name: "category",
  components: {
    DatePicker,
    statusIcon 
  },
  data() {
    return {
      tablefields: [
        { key: "goods_thumbnail", label: "이미지", sortable: false },
        { key: "purchase_name", label: "상품명", sortable: false },
        { key: "purchase_payment", label: "결제금액", sortable: false },
        { key: "payment_date", label: "결제일", sortable: false },
        { key: "purchase_status_str", label: "구매상태", sortable: false },
      ],
      add: false,
      save: false,
      edit: null,
      item: "",
      index: null,
      addNew: false,
      filter: null,
      totalRows: 1,
      selectedSku : "",
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15],
      selectedKey: "",
      selectMode: "single",
      imgUrl: imgUrl,
      siteCode: '',
      startDate: '',
      endDate: new Date(),
      search: '',
      purchase_status: '' ,
      sortColumn: 'payment_date',
      offset : 0,
      limit : 10,
    };
  },
  created() {
    // this.$store.dispatch("product/getProducts");
    let managerInfo = localStorage.getItem('userInfo');
    if (managerInfo) {
      managerInfo = JSON.parse(managerInfo);
    }

    let now = new Date();
    let endDate = new Date().toISOString().substring(0,10);	
    
    let datetmp = endDate.replace(/-/g,'');			// - 는 모두 제거
     
    let y = parseInt(datetmp.substr(0, 4));
    let m = parseInt(datetmp.substr(4, 2));
    let d = parseInt(datetmp.substr(6,2));
  
    let startDate = new Date(y, m - 1, d -14);
    startDate = startDate.toISOString().substring(0,10);

    console.log(["startDate", startDate]);
    console.log(["endDate", endDate]);
    this.startDate = startDate;
    this.endDate = endDate;

    console.log(["startDate", this.startDate]);
    console.log(["endDate", this.endDate]);

    
    this.siteCode = managerInfo.site_code;

    // siteCode, startDate, endDate, search, purchase_status, sortColumn, offset, limit
    const param = {
      siteCode: managerInfo.site_code, 
      startDate : startDate,
      endDate : endDate,
      search : '',
      purchase_status : '',
      sortColumn : 'payment_date',
      offset : 0,
      limit : 10
    }

    
    this.$store.dispatch("purchase/getPurchaseList", param);
  },
  computed: {
    ...mapGetters({
      items: "purchase/getPurchaseList"
    }),
    selectedRows() {
      return this.items.filter(item => item.delete);
    },
    sortOptions() {
      // Create an options list from our fields
      return this.tablefields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    },
    editableFields() {
      return this.tablefields.filter(field => {
        return field.editable === true;
      });
    }
  },
  mounted() {

    // Set the initial number of items
    console.log([ "this.items",this.items ]);
    //this.totalRows = this.items.length;
  },
  methods: {
    onEdit(sku) {
      //  if (sku !== "undefined") {
      this.edit = this.edit !== sku ? sku : null;
      //  }
    },
    getIndex(sku) {
      this.selectedSku = sku
    },
    onSave(id) {
      this.save = false;
      this.add = false;
      this.edit = 1000;
    },
    doCancel(id) {
      this.edit = null;
      this.item = null;
    },
    deleteRow(sku) {
      this.items.splice(this.items.findIndex(e => e.sku === sku),1);
    },
    rowSelected(item) {
      if (item[0]) {
        this.edit = this.edit !== item[0].sku ? item[0].sku : null;
      }
    },
    addRecord() {
      this.add = true;
      this.items.unshift({
        image: "main.jpg",
        sku: "",
        name: "",
        price: "",
        category: ""
      });
      this.edit = "";
      this.save = true;
    },
    searchData() {
      this.add = false;
      this.items.splice(0, 1);
    },

    getImgUrl(path) {
      return require("@/assets/images/dashboard/product/" + path);
    },
    formData(e) {
      console.log("modal data", e);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    info(item, index, e) {
      console.log(item, index, e);
    },
    myRowClickHandler(record, index) {
      this.selectedKey = index;
      // 'record' will be the row data from items
      // `index` will be the visible row number (available in the v-model 'shownItems')
      //  console.log(record, index);  // This will be the item data for the row
    },
    doEdit(item) {
      this.$set(item, "temp", JSON.parse(JSON.stringify(item)));
      this.$set(item, "editing", true);
    },
    doSave(item) {
      this.$set(item, "editing", false);
      for (let key in item.temp) {
        if (item[key] != item.temp[key]) {
          item[key] = item.temp[key];
        }
      }
    },
    selectItem(item) {
      this.$log.debug(['selectItem', item]);
      this.item = item;

      // block 정보 가져오기

      // nodeApi.postBlockList(item).then( res => {
      //   if (res.data.success) {
      //     this.$log.debug(['result ', res.data])
      //     this.blockList = res.data.block_list;
      //   }
      // }).catch( err => {
      //
      // })

      // this.$bvModal.show('event-view-modal');
      // this.$router.push({name: 'addEvent', params: { item: item }, props: true});
    },
    searchButton(){
      console.log(["startDate",this.startDate]);
      console.log(["endDate",this.endDate]);
      console.log(["search",this.search]);
      console.log(["purchase_status",this.purchase_status]);
      console.log(["sortColumn",this.sortColumn]);
      console.log(["offset",this.offset]);
      console.log(["limit",this.limit]);

      const param = {
        siteCode: this.site_code, 
        startDate : this.startDate,
        endDate : this.endDate,
        search : this.search,
        purchase_status : this.purchase_status,
        sortColumn : this.sortColumn,
        offset : this.offset,
        limit : this.limit
      }

      
      this.$store.dispatch("purchase/getPurchaseList", param);

    }
  }
};
</script>

<style lang="scss" scoped>
table.text-center * {
  text-align: center;
}
</style>

