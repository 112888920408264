<template>
  <div>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="토큰 추가" title="토큰 추가" />
      </div>
      <div class="col-sm-12">
        <div class="row">
          <div class="col-xl-6">
            <div class="form-group">
                <label class="form-label d-inline" for="wallet_address">지갑주소</label>
                <input type="text" class="form-control" id="wallet_address" name="wallet_address" v-model="wallet_address" aria-describedby="adNameHelp" placeholder="지갑주소" required>
            </div>
            <div class="form-group">
                <label class="form-label d-inline" for="amount">금액</label>
                <input type="text" class="form-control" id="amount" name="amount" v-model="amount" aria-describedby="adNameHelp" placeholder="금액" required>
            </div>
            <b-button class="btn btn-primary" variant="primary" @click="save">토큰 추가</b-button>
            <!-- <b-button class="btn btn-primary" variant="primary" @click="createname">부치고 계정 추가</b-button> -->
          </div>
          <div class="col-xl-6">
            <div class="form-group">
                <label class="form-label d-inline" for="wallet_address">토큰 충전 내역</label>
                <table>
                    <thead>
                        <th style="">충전날짜</th>
                        <th >지갑주소</th>
                        <th >충전토큰금액</th>
                    </thead>
                    <tbody>
                        <tr v-for="(token, index) in token_history" v-bind:key="index">
                            <td>{{ token.created_at }}</td>
                            <td>{{ token.wallet_address }}</td>
                            <td>{{ token.amount }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import {imgUrl} from '../../constants/config';
import nodeApi from "../../api/nodeApi";
// Register components in your 'main.js'
export default {
  name: "addToken",
  components: {  },
  data() {
    return {
      wallet_address:'',
      amount:'',
      token_history:[],
    };
  },
  async created() {
  },
  computed: {
    
  },
  mounted() {
    nodeApi.posetTokenHistory().then( res => {
        console.log(['res', res.data]);
        if(res.data.success){
          this.token_history = res.data.tokenHistory
        }
    }).catch( err => {
        alert(err.toString());
        this.$log.error(err.toString());
    });
    
  },
  methods: {
      save() {
          this.$log.debug(['save', this.wallet_address, this.amount]);
          const frm = new FormData();
          frm.append('wallet_address', this.wallet_address);
          frm.append('amount', this.amount);

          nodeApi.addToken(frm).then( res => {
              console.log(['res', res.data]);
              if (res.data.success) {
                  alert("토큰이 추가 되었습니다.");
                  // this.$emit("transResult", 'success');
              }else{
                  alert(res.data.message);
                  // this.$emit("transResult", 'fail');
              }
          }).catch( err => {
              alert(err.toString());
              this.$log.error(err.toString());
          });
      },
      createname(){
        nodeApi.buchigoCreateName().then( res => {
              console.log(['res', res.data]);
              if (res.data.success) {
                  alert("계정이 추가 되었습니다.");
                  // this.$emit("transResult", 'success');
              }else{
                  alert(res.data.message);
                  // this.$emit("transResult", 'fail');
              }
          }).catch( err => {
              alert(err.toString());
              this.$log.error(err.toString());
          });
      }
  }
};
</script>

<style lang="scss" scoped>
table.text-center * {
  text-align: center;
}

table{
width: 100%;
text-align : left;
border: 2px solid  darkgray;
}

table th{
    padding : 12px;
    border: 1px solid  darkgray;
    
}
table td{
    padding : 12px;
    border: 1px solid  darkgray;
}
</style>


  
  