<template>
  <div class="page-wrapper">
    <div class="authentication-box">
      <div class="container">
        <div class="row m-lg-0">
          <div class="col text-center">
            <img src="@/assets/images/wonhago/wonhago-en.png" class="w-50"
            style="margin-bottom: 50px;"
            >
          </div>
          <div class="col-md-12 p-0 card-right">
            <div class="card tab2-card">
              <div class="card-body">
                <b-tabs content-class="mt-3">
                  <b-tab title="Login" data-feather="user" active>
                    <login></login>
                  </b-tab>
                  <!-- registration form start -->
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
<!--       <router-link to="/"> <a class="btn btn-primary back-btn"-->
<!--          > <feather type="arrow-left"></feather>back</a-->
<!--        ></router-link>-->
      </div>
    </div>
  </div>
</template>

<script>
import login from "../authentication/login.vue";
import registration from "../authentication/registration.vue";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";

export default {
  components: { login, registration, swiper, swiperSlide },
  data() {
    return {
      type: "password",
      password: "",
      username: "",
      submitted: false,
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 1,
        freeMode: true,
        infiniteMode: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          renderBullet(index, className) {
            return `<span class="${className} swiper-pagination-bullet-custom"></span>`;
          }
        }
      }
    };
  }
};
</script>
