<template>
  <b-modal id="goods-view-modal" ok-title="확인" cancel-title="닫기" title="상품 정보 보기" cancel-variant="dark" @ok="editGoods" >
    <div class="card card-default ">
      <div class="card-body">
        <div class="row">
          <div class="col ">
            <div class="row form-group">
              <label class="col-2 col-form-label ">메인화면 상품 이미지(가로형:pc버전)</label>
              <div class="col">{{item.goods_img}}
                <img :src="imgUrl+item.goods_img" class="w-50">
              </div>
            </div>
            <div class="row form-group">
              <label class="col-2 col-form-label ">메인화면 상품 이미지(세로형:모바일)</label>
              <div class="col">{{item.goods_img2}}
                <img :src="imgUrl+item.goods_img2" class="w-50">
              </div>
            </div>
            <div class="row form-group">
              <label class="col-2 col-form-label ">상품이미지</label>
              <div class="col">{{item.goods_thumbnail}}
                <img :src="imgUrl+item.goods_thumbnail" class="w-50">
              </div>
            </div>
            <div class="row form-group">
              <label class="col-2 col-form-label ">상품명 제목</label>
              <div class="col">
                <input type="text" class="form-control" name="goods_name" v-model="item.goods_name">
              </div>
            </div>
            <div class="row form-group">
              <label class="col-2 col-form-label">판매금액</label>
              <div class="col-4">
                <input type="text" class="form-control" name="goods_price" v-model="item.goods_price">
              </div>
              <label class="col-2 col-form-label">재고</label>
              <div class="col">
                <input type="text" class="form-control" name="goods_qty" v-model="item.goods_qty">
              </div>
            </div>
            <div class="row form-group d-none">
              <label class="col-2 col-form-label">판매유형</label>
              <div class="col-4 col-form-label">
                {{item.goods_type_str}}
              </div>
              <label class="col-2 col-form-label">할인구분</label>
              <div class="col col-form-label">
                {{item.discount_type_str}}
              </div>
            </div>
            <div class="row form-group">
              <label class="col-2 col-form-label">상태</label>
              <div class="col-4">
                <b-select id="eventStatus" name="event_status" class="form-control" v-model="item.goods_status" :options="codes" />
<!--                <select class="form-control" name="goods_status">-->
<!--                  <option v-for="item in codes" :value="item.code" :selected="item.goods_status == item.code" >{{item.code_name}}</option>-->
<!--                </select>-->
              </div>
              <label class="col-2 col-form-label">등록일</label>
              <div class="col col-form-label">
                {{item.created_at}}
              </div>
            </div>
            <div class="option-container" v-if="item.options">
              <div class="row form-group">
                <label class="col col-form-label">옵션정보</label>
                <div class="col-auto col-form-label">
                  <button class="btn btn-primary" @click="optionAdd">옵션 추가</button>
                </div>
              </div>
              <div class="row form-group">
                <div class="col">
                  <table class="table table-striped">
                    <thead>
                    <tr>
                      <th></th>
                      <th>옵션명</th>
                      <th>옵션가격</th>
                      <th>삭제</th>
                    </tr>
                    </thead>
                    <draggable v-model="item.options" tag="tbody">
                      <tr v-for="(option, index) in item.options" id="goods-sort" :key="option.option_seq">
                        <input type="hidden" name="option_seqs[]" :value="option.option_seq">
                        <td class="align-middle"><i class="fa fa-align-justify handle"></i></td>
                        <td><input type="text" class="form-control" name="option_names[]" v-model="option.option_name"></td>
                        <td><input type="text" class="form-control" name="option_prices[]" v-model="option.option_price"></td>
                        <td class="align-middle"><i class="fa fa-minus-circle text-danger fa-lg" aria-hidden="true" @click="optionDelete(option, index)"></i></td>
                      </tr>

                    </draggable>
                    <tr v-if="item.options.length == 0">
                      <td colspan="4" class="text-center">등록된 옵션이 없습니다.</td>
                    </tr>
                  </table>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {imgUrl} from '../../constants/config';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ko';

import nodeApi from "../../api/nodeApi";

export default {
  name: "GoodsViewModal",
  components: { DatePicker },
  props: {
    item: {
      goods_name: ''
    },
    blockList: []
  },
  data() {
    return {
      imgUrl: imgUrl,
      codes: [
        {value: 'GOODS_STATUS01', text: '판매대기'}
        ,{value: 'GOODS_STATUS02', text: '판매중'}
        ,{value: 'GOODS_STATUS03', text: '종료'}
      ],
      is_show: false,
      selectedQr: '',
    }
  },
  created() {
    this.$log.debug(['GoodsViewModal', this.item]);
  },
  methods: {
    viewQr(obj) {
      this.$log.debug(['viewQr', obj, this.is_show])
      // $('#qrCode').html(obj.target.outerHTML);
      this.selectedQr = obj.target.outerHTML
      this.is_show = !this.is_show;
    },
    closeQr() {
      this.is_show = !this.is_show;
      this.selectedQr = '';
      // this.qr = '';
    },
    editGoods() {
      // this.$log.debug(['editGoods', this.item, this.blockList]);
      let param = this.item;
      // param.blockList = this.blockList;
      nodeApi.putGoods(param).then( res => {
        if (res.data.success) {
          nodeApi.showToast(res.data.msg, "success");
        }
      }).catch( err => {
        nodeApi.showToast(res.data.msg);
      })
    }
  }
}
</script>

<style scoped>
.mx-datepicker {
  width: 8rem;
}
</style>
