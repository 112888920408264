import Vue from "vue";
import nodeApi from "../../api/nodeApi";

const state = {
    purchaseList: [],


}

const getters = {
    getPurchaseList: (state) => {
        console.log(['getters getPurchaseList', state]);
        return state.purchaseList;
    }
}

const actions = {
    getPurchaseList: (context, payload) => {
        console.log(['actions getPurchaseList', context , payload]);
        // Vue.$log.debug(['getEventList action ', payload]);

        nodeApi.postPurchaseList(payload).then( res => {
            Vue.$log.debug(['store postPurchaseList res', res]);
            if (res.data.success) {
                context.commit('getPurchaseList', res.data.purchase_list);
            }
        }).catch( err => {
            Vue.$log.error(err.toString());
        });
    }
}

const mutations = {
    getPurchaseList: (state, payload) => {
        console.log('mutations getPurchaseList');
        state.purchaseList = payload;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
